.f1_4r{font-size: 1.4rem;}
.lt-spc2{letter-spacing: 0.125rem;}
.video-bg {
  position: relative
}

.video-bg img:first-child {
  width: 100%;
  display: block
}

.banner1-info.new {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.line-circle{
  width: 100%;
  position: absolute;
  top: 12%;
  display: flex;
  align-items: center;
}
.line-circle .left{
  position: absolute;
  display: flex;
  align-items: center;
  width: 7%;
  left: 0;
}
.line-circle .text{
  color: #fff;
  font-size: 2rem;
  position: absolute;
  display: inline-block;
  text-align: center;
  left: 8%;
  width: 16%;
}
.line-circle .right{
  position: absolute;
  width:88%;
  right: 0;
  display: flex;
  align-items: center;
}
.line-circle .line{
  width: 100%;
  height: 1px;
  background-color: #3CB1BE;
}
.line-circle .circle{
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #17f2f4;
}
.bg-img {
  display: block;
}
.first-img{
  width: 55%;
  position: absolute;
  top: 39%;

}
.jc{
  left:50%;
  transform: translateX(-50%);
}
.wording {
  position: absolute;
  /* width: 500px; */
  width: 100%;
  /* right: 55%; */
  /* text-align: center; */
  /* top: 30%; */
  color: #fff;
}

/* .move-logo {
  background: url("/img/banenr-logo.png") no-repeat center 0;
  background-size: auto 66px;
  height: 66px;
  transform: translateY(50px);
  opacity: 0;
} */


.move-desc {
  font-size: 1.5rem;
  transform: translateY(3.125rem);
  opacity: 0;
  transition: all 0.5s cubic-bezier(.8, 0, .2, 1);
  letter-spacing: 0.2rem;
  margin-bottom: 1.7rem;
  margin-top: 2rem;
}

.move-title {
  color: #17f2f4;
  font-size: 2.5rem;
  font-weight: 600;
  transform: translateY(3.125rem);
  opacity: 0;
  transition: all 0.5s cubic-bezier(.8, 0, .2, 1);
  margin-bottom: 2%;
}

.move-down { 
  /* text-align: center; */
  transform: translateY(3.125rem);
  opacity: 0;
  font-size: calc(0.8rem + 12px);
  transition: all 0.5s cubic-bezier(.8, 0, .2, 1);
  overflow: hidden;
}
.move-down-a{
  text-align: center;
}
@media screen and (max-width:1366px) {
  .wording {
    /* right: 54%; */
    top: 17%;
}
}

@media screen and (min-width:1367px) and (max-width:1920px) {
  .wording {
    top: 12%;
    /* right: 57%; */
}
}


.show .move-logo {
  animation: logomove 970ms cubic-bezier(.8, 0, .2, 1);
  animation-fill-mode: forwards;
}

.show .move-desc {
  animation: descmove 1000ms cubic-bezier(.8, 0, .2, 1);
  animation-fill-mode: forwards;
  animation-delay: 160ms
}

.show .move-title {
  animation: titlemove 1000ms cubic-bezier(.8, 0, .2, 1);
  animation-fill-mode: forwards;
  animation-delay: 80ms
}

.show .move-down {
  animation: btnmove 1000ms cubic-bezier(.8, 0, .2, 1);
  animation-fill-mode: forwards;
  animation-delay: 240ms
}

@keyframes logomove {
  51.55% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes titlemove {
  50% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes descmove {
  50% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes btnmove {
  51.55% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}



.move-down a {
  color: #426cbc;
  font-size: 1.3rem;
  line-height: 2.35rem;
  display: inline-block;
  width: 13rem;
  height: 2.7rem;
  border-radius: 1.5rem;
  background-color: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  overflow: hidden
}


.move-down a span {
  display: block;
  height: 100%;
  transition: transform 0.2s;
}

.move-down .a1:hover {
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: 0 0 0 0.25rem #fff inset;
  -moz-box-shadow: 0 0 0 0.25rem #fff inset;
  box-shadow: 0 0 0 0.25rem #fff inset;
}

.move-down a:active {
  background-color: rgba(255, 255, 255, 0.7)
}

.move-down a:hover span {
  transform: translateY(-100%)
}


.intro-box{
  /* padding: 20px; */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  
}
.intro-header{
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 3rem;
}
.intro-title{
  /* text-align: center; */
  color: #3CB1BE;
  font-size: 2.5rem;
  /* margin: 20px 0 40px 0; */
  margin-left: 0.3rem;
  font-weight: 600;
}

.intro-content{
  display: table;
  font-size: 1.5rem;
  letter-spacing: 1px;

}


.intro-box:nth-child(2n-1){
  /* background-color: #ededed; */
  align-items: center;

}
.intro-header :first-child{
  /* margin-top: 6.5rem; */
}
.i-box{
  display: table-cell;
  vertical-align: middle;
}
.intro-img {
  width: 60%;
}

.intro-img img{
  width: 100%;
}
.right-box{
  padding-left: 1.875rem;
}

.point-desc{
  font-size: 1.25rem;
  margin-bottom: 0.625rem;
}

.slogan{
  background-color: #4c8045;
  color: #fff;
  padding: 6.25rem;
  font-size: 2.5rem;
  text-align: center;
}

.bgc6{
  background-color: #333;
}
.c1{
  color: #fff;
}
.menuDiv{
  margin-top: 3.125rem;
  position: absolute;
}

.memuLi{
list-style: none;
text-align: center;
background-color: #333;
padding: 0.625rem 0px;
margin-left: 0.315rem;


}
.menuDiv  div:hover{

  background-color: #4c8045;

}
.btnMobile{
  background: #fff;
  border: 1px solid #fff;
  margin-top: 0.315rem;
  width: 10rem;
  height: 2.2rem;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 1rem; */
  margin: auto;
}
.btnMobile:last-child{
  background-color: #000424;
}
.flexBox{
  display: flex;
  flex-wrap: wrap;
}
.concat{
  display: none;
}
.scrollTop .concat{
  display: block;
}